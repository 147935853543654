:root{
	--navbarHeight: 115px;
}

.Navbar{
	height: var(--navbarHeight);
	padding: 10px 25px;
	position: sticky;
	top: 0;
}

.Navbar .navTitle{
	font-size: 10pt;
}

.Navbar h2{
	margin: 0;
	padding-bottom: 3px;
	font-size: 16pt;
}

.Navbar .branding{
	text-align: right;
}

.Navbar .branding img{
	width: min(100%, 200px);
}
