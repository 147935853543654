.Panel{
	color: var(--mainTextColor);
	padding: 20px 30px;
	max-width: 500px;
	margin: auto;
}

.Panel .input{
	margin-bottom: 27px;
}

.Panel .message{
	padding-bottom: 15px;
	color: red;
}

.Panel .message:empty{
	display: none;
}

.Panel h1{
	font-weight: bold;
	padding-bottom: 20px;
	line-height: 25pt;
}

.Panel h2{
	font-weight: bold;
	padding-bottom: 19px;
	line-height: 25pt;
	text-align: left;
}

.Panel h3{
	text-align: left;
	line-height: 23pt;
}

.Panel .controls{
	margin-top: 70px;
}

.Panel p{
	text-align: left;
	line-height: 15pt;
}

.Panel li{
	padding: 7px 0;
}

.Panel .yesNo{
	position: fixed;
	bottom: var(--footerHeight);
	margin-bottom: 20px;
	left: 0;
	width: 100%;
}

.Panel .yesNo .button{
	width: min(40%, 100px);
	margin: 0 10px;
}
