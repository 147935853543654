:root{
	--footerHeight: 55px;
}

.Footer{
	background-color: white;
	position: fixed;
	bottom: 0px;
	padding: 20px;
	width: 100%;
	overflow: hidden;
	height: var(--footerHeight);
}

.Footer img{
	height: 20px;
}
